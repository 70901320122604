jQuery(document).ready(function () {
    NAREX.thumbnailedNavigation = new NAREX.ThumbnailedNavigation(
        jQuery('#products-submenu').parent('li.dropdown'),
        jQuery('#main-navigation')
    );
});
/* MAIN - END */



NAREX.ThumbnailedNavigation = function ($dropdownMenu, $mainMenu) {


    // main menu
    this.$mainMenu = $mainMenu;


    // toggleMenuTrigger
    this.$toggleMenuTrigger = jQuery('a.dropdown-toggle', $dropdownMenu);


    // puvodni menu
    this.$originalMenu = jQuery('#products-submenu', $dropdownMenu);


    // enable/disable original menu - BEGIN
    this.originalMenuEnable = function () {
        this.$originalMenu.removeAttr('style');
        this.$toggleMenuTrigger.attr('data-toggle', 'dropdown');
    };

    this.originalMenuDisable = function () {
        $dropdownMenu.removeClass('open');
        this.$originalMenu.hide();
        this.$toggleMenuTrigger.removeAttr('data-toggle');
    };
    // enable/disable original menu - END


    // nove menu (thumbnailed) - $menuItems
    this.$thumbnailedMenu = this.createThumbnailedMenu(
            jQuery('#products-submenu li', $dropdownMenu)
        );


    // click event - BEGIN
    this.$toggleMenuTrigger.on('click', jQuery.proxy(function (e) {
        e.preventDefault();

        // xs - BEGIN
        if (BsTools.Screen.isXs() == true) {
            if (this.$thumbnailedMenu.is(':visible')) {
                this.$thumbnailedMenu.slideUp('speed');
            }
            this.originalMenuEnable();
        }
        // xs - END

        // ostatni velikosti - BEGIN
        else {
            this.originalMenuDisable();
            this.setMenuSizeAndPosition();
            this.$thumbnailedMenu.slideToggle();
        }
        // ostatni velikosti - END

    }, this));
    // click event - END


    // window resize event - BEGIN
    jQuery(window).on('resize', jQuery.proxy(function (e) {
        this.setMenuSizeAndPosition();
    }, this));
    // window resize event - END

};




    // zobrazovaci kontroler - BEGIN
    NAREX.ThumbnailedNavigation.prototype.displayControler = function () {

    };
    // zobrazovaci kontroler - END



    // vytvoreni thumbnailed menu - BEGIN
    NAREX.ThumbnailedNavigation.prototype.createThumbnailedMenu = function ($menuItems) {

        // thumbnailed menu container
        var $menu = jQuery('<div />')
            .appendTo('body')
            .addClass('thumbnailed-submenu')
            .css({position: 'absolute'})
            .css({
                left: '-100000px',
                top: '-100000px'
            });

        // grid container
        var $container = jQuery('<div />').addClass('pp-container');
        $container.appendTo($menu);

        // itarace nas linky - BEGIN
        var html = '';
        for (var i=0; i<$menuItems.length; i++) {

            // row zacatek
            if (i%6 == 0) {
                html += '<div class="pp-row">';
            }

                // item
                var $item = jQuery($menuItems[i]);
                html += this.createThumbnailedMenuItem($item);

            // row konec
            if ((i+1)%6 == 0 || i == $menuItems.length-1) {
                html += '</div>';
            }
        }
        $container.html(jQuery(html));
        // itarace nas linky - END

        $menu.slideToggle();
        return $menu;

    };
    // vytvoreni thumbnailed menu - END



    // vytvorteni polozky thumbnailed menu - BEGIN
    NAREX.ThumbnailedNavigation.prototype.createThumbnailedMenuItem = function ($menuItem) {
        html  = '<div class="pp-item-container">';
        html += '   <div class="pp-item">';
        html += '       <a href="' + $menuItem.children('a').attr('href') + '" class="thumbnail-link">';
        html += '           <img src="' + $menuItem.children('a').data('navThumbnail') + '" class="thumbnail" />';
        html += '       </a>';
        html += '       <p class="caption"><a href="' + $menuItem.children('a').attr('href') + '">' + $menuItem.children('a').text() + '</a></p>';
        html += '   </div>';
        html += '</div>';
        return html;
    };
    // vytvorteni polozky thumbnailed menu - END



    NAREX.ThumbnailedNavigation.prototype.setMenuSizeAndPosition = function () {
        this.setMenuSize();
        this.setMenuPosition();
    };



    NAREX.ThumbnailedNavigation.prototype.setMenuSize = function () {
        this.$thumbnailedMenu.css({
            width: this.$mainMenu.outerWidth()
        });
    };



    NAREX.ThumbnailedNavigation.prototype.setMenuPosition = function () {
        this.$thumbnailedMenu.css({
            left: this.$mainMenu.offset()['left'],
            top: this.$mainMenu.offset()['top'] + this.$mainMenu.outerHeight()
        });
    };
