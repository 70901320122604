// main namespace
var BsTools = BsTools || {};

// modul namespace
BsTools.Screen = {};

// modul shortcut
var BSTS = BsTools.Screen;




/**
 * Bootstrap default media queries breakpoints
 **/
BSTS.__defaults = {
    'screen-sm-min': '768px',
    'screen-md-min': '992px',
    'screen-lg-min': '1200px'
};




/**
 * options (with init setting)
 **/
BSTS.options = {};
BSTS.options = jQuery.extend(BSTS.options, BSTS.__defaults);




/**
 * set breakpoints options (while use custom)
 **/
BSTS.setOptions = function (options) {
    jQuery.extend(BSTS.options, options);
};




/**
 * return viewport size
 **/
BSTS.size = function () {
    return {
        width: jQuery(window).width(),
        height: jQuery(window).height()
    };
};




/**
 * return true when viewport corresponds to 'xs'
 **/
BSTS.isXs = function () {
    if (BSTS.size().width < parseInt(BSTS.options['screen-sm-min'])) {
        return true;
    }
    else {
        return false;
    }
};




/**
 * return true when viewport corresponds to 'sm'
 **/
BSTS.isSm = function () {
    if (BSTS.size().width >= parseInt(BSTS.options['screen-sm-min'])
        && BSTS.size().width < parseInt(BSTS.options['screen-md-min'])) {
        return true;
    }
    else {
        return false;
    }
};




/**
 * return true when viewport corresponds to 'md'
 **/
BSTS.isMd = function () {
    if (BSTS.size().width >= parseInt(BSTS.options['screen-md-min'])
        && BSTS.size().width < parseInt(BSTS.options['screen-lg-min'])) {
        return true;
    }
    else {
        return false;
    }
};




/**
 * return true when viewport corresponds to 'lg'
 **/
BSTS.isLg = function () {
    if (BSTS.size().width >= parseInt(BSTS.options['screen-lg-min'])) {
        return true;
    }
    else {
        return false;
    }
};




/**
 * return viwport corresponding shortcut (xs|sm|md|lg)
 **/
BSTS.device = function () {
    if (BSTS.isXs() === true) {
        return 'xs';
    }
    else if (BSTS.isSm() === true) {
        return 'sm';
    }
    else if (BSTS.isMd() === true) {
        return 'md';
    }
    else {
        return 'lg';
    }
}




/**
 * device (viewport) status cache
 **/
BSTS.__deviceCache = null;




/**
 * Device events trigger
 * fire only when device status changed
 * - BsTools.Screen.switchDevice with type shortcut in e.device
 * - BsTools.Screen.switchDeviceTo.'shortcut'
 **/
 BSTS.deviceEventsTrigger = function (withoutCache) {

     var baseEventName = 'BsTools.Screen.switchDevice';

     if (typeof withoutCache == 'undefined') {
         var withoutCache = false;
     }

     var deviceStatus = BSTS.device();
     var previousDeviceStatus = BSTS.__deviceCache;

     if (deviceStatus != previousDeviceStatus
         || withoutCache == true) {

         if (withoutCache == false) {
             BSTS.__deviceCache = deviceStatus;
         }

         // switch device event
         var event = jQuery.Event(baseEventName);
         event.device = deviceStatus;
         jQuery(document).trigger(event);

         // switch device to event
         var event = jQuery.Event(baseEventName + 'To.' + deviceStatus);
         jQuery(document).trigger(event);

     }

 };




 /**
  * init fire DeviceEventsTrigger without cache (win.load)
  **/
 jQuery(window).load(function () {
     BSTS.deviceEventsTrigger(true);
 });




 /**
  * init fire DeviceEventsTrigger without cache (doc.ready)
  **/
 jQuery(document).ready(function () {
     BSTS.deviceEventsTrigger(true);
 });




 /**
  * hook DeviceEventsTrigger to window.resize event
  **/
jQuery(window).resize(function () {
    BSTS.deviceEventsTrigger();
});
