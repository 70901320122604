jQuery(document).ready(function() {
    (function($, undefined){

      var vcards = NAREX.searchVcards('#places_results_list');

      if (typeof NAREX.pointsOfSaleForm != 'undefined') {

        var map = new NAREX.salesAndServiceMap(
            jQuery('#google_map_canvas'), // $canvas
            vcards, // vCards
            NAREX.getSalesAndServiceMapForm(NAREX.pointsOfSaleForm), // form fields
            { zoom: 7 }
          );

        var searchForm = new NAREX.salesAndServiceSearchForm(
            NAREX.getSalesAndServiceMapForm(NAREX.pointsOfSaleForm), // form fields
            jQuery('.geocoder-return-empty-result'), // message box 'zadny vysledek'
            jQuery('.geocoder-return-many-results') // message box 'mnoho vysledku'
          );

      }

    }).call(NAREX, jQuery);
});
/* APP - END ------------------------------------------------ */





/* =============================================================================
searchVcards - BEGIN
- zvraci pole vCards nalezenych v prostoru definovane jq_selectorem
----------------------------------------------------------------------------- */
/* PARSOVANI vCards - BEGIN */
NAREX.searchVcards = function (jq_selector) {

    var vcards = new Array;

    jQuery.each(jQuery('.vcard', jq_selector), function (i, elm) {

        jq_elm = jQuery(elm);
        var vcard = {};
        vcard.latitude = jQuery('.latitude', jq_elm).text();
        vcard.longtitude = jQuery('.longitude', jq_elm).text();
        vcard.title = jQuery('h2', jq_elm).text();
        vcard.street_address = jQuery('.street-address', jq_elm).text();
        vcard.postal_code = jQuery('.postal-code', jq_elm).text();
        vcard.locality = jQuery('.locality', jq_elm).text();
        vcard.country_name = jQuery('.country-name', jq_elm).text();
        vcards.push(vcard);

    });

    return vcards;

};
/* -----------------------------------------------------------------------------
searchVcards - END
============================================================================= */




/* =============================================================================
getSalesAndServiceMapForm - BEGIN
- vraci jQuery fields formulare
----------------------------------------------------------------------------- */
NAREX.getSalesAndServiceMapForm = function (formDefinitions) {
  return {
      '$address': jQuery('#' + formDefinitions.addressFieldId),
      '$lat': jQuery('#' + formDefinitions.latFieldId),
      '$lng': jQuery('#' + formDefinitions.lngFieldId),
      '$distanc': jQuery('#' + formDefinitions.distancFieldId),
      '$send': jQuery('#' + formDefinitions.sendButtonId)
  };
};
/* -----------------------------------------------------------------------------
getSalesAndServiceMapForm - END
============================================================================= */





/* =============================================================================
salesAndServiceMap - BEGIN
- zajistuje funkci zobrazovani servisnich a prodejnich mist pomoci Google Maps
----------------------------------------------------------------------------- */
NAREX.salesAndServiceMap = function ($canvas, vcards, formFields, options) {

  // defaults - BEGIN
  var defaults = {
    centerLat:              49.740568,
    centerLng:              15.335877,
    zoom:                   10,
    mapType:                google.maps.MapTypeId.ROADMAP,
    scaleControl:           true,
    centerPointMarkerIcon:  '/assets/dist/images/google-maps-markers-center-point.png',
    vCardMarkerIcon:        '/assets/dist/images/google-maps-markers-place-point.png'
  };
  // defaults - END


  // options
  this.options = jQuery.extend(defaults, options);


  // center point
  this.centerPoint = this.getCenterPoint(formFields);


  // mapa
  try {
    this.map = this.createMap($canvas);
  }
  catch (err) {
    return;
  }

  // markers
  this.markers = new NAREX.salesAndServiceMap.MarkerStorage(this.map);

  // center point marker
  this.markers.add(this.centerPoint.lat(), this.centerPoint.lng(),
      null,
      this.options.centerPointMarkerIcon
    );

  // vCard markers
  for (var i = 0; i < vcards.length; i++) {
    this.markers.add(vcards[i].latitude, vcards[i].longtitude,
        vcards[i].title,
        this.options.vCardMarkerIcon,
        this.createMarkerInfoWindow(vcards[i])
      );
  }


};


  NAREX.salesAndServiceMap.prototype.getCenterPoint = function (formFields) {

    var lat = jQuery.trim(formFields.$lat.val());
    var lng = jQuery.trim(formFields.$lng.val());

    var defaultCenterPoint = new google.maps.LatLng(this.options.centerLat, this.options.centerLng);

    // nejaky form field nevyplnen
    if (lat == '' || lng == '') {
      return defaultCenterPoint;
    }

    else {
      return new google.maps.LatLng(lat, lng);
    }

  };


  NAREX.salesAndServiceMap.prototype.createMap = function ($canvas) {

    // canvas
    if ($canvas.length != 1) {
      throw 'Map canvas definition is failed.';
    }

    var canvas = $canvas.get(0);

    // mapa
    try {
      var map = new google.maps.Map(canvas, {
        zoom: this.options.zoom,
        center: this.centerPoint,
        mapTypeId: this.options.mapType,
        scaleControl: this.options.scaleControl
      });
      return map;
    }
    catch (err) {
      throw 'Google map create failed.';
    }

  };



  NAREX.salesAndServiceMap.prototype.createMarkerInfoWindow = function (vcard) {
    var html = '';
    html += '<div class="infowindow">';
    html += ' <p>';
    html += '   <strong>' + vcard.title + '</strong><br />';
    html +=     vcard.street_address + '<br />';
    html +=     vcard.postal_code + ' ' + vcard.locality + '<br />';
    html +=     vcard.country_name;
    html += ' </p>';
    html += '</div>';
    return html;
  };




NAREX.salesAndServiceMap.MarkerStorage = function (map) {
  this.map = map;
  this.markers = new Array();
};


  NAREX.salesAndServiceMap.MarkerStorage.prototype.add = function (lat, lng, title, icon, infoWindow) {

    try {

      // marker
      var latLng = new google.maps.LatLng(lat, lng);
      var marker = new google.maps.Marker({
        map: this.map,
        position: latLng,
        title: title,
        icon: icon
      });

      // infowindow
      if (typeof infoWindow != 'undefined') {
        marker.infowindow = new google.maps.InfoWindow({ content: infoWindow });
        var map = this.map;
        google.maps.event.addListener(marker, 'click', function() {
            this.infowindow.open(map, marker);
        });
      }

      // vlozeni do storage
      this.markers.push(marker);

    }

    catch (err) {
      console.log('nejaka chyba pri pridani markeru');
      return;
    }

    // autozoom
    this.autoZoom();

  };


  NAREX.salesAndServiceMap.MarkerStorage.prototype.autoZoom = function () {

    // jen pro vice nez jeden marker
    if (this.markers.length <= 1) {
      return;
    }

    var gmap_markers_bounds = new google.maps.LatLngBounds();

    for (var i=0; i<this.markers.length; i++) {
        gmap_markers_bounds.extend(this.markers[i].position);
    }

    this.map.fitBounds(gmap_markers_bounds);

  };

/* -----------------------------------------------------------------------------
salesAndServiceMap - END
============================================================================= */






/* =============================================================================
salesAndServiceSearchForm - BEGIN
- pomoci geocoderu vyplnuje vyhledavaci formular
----------------------------------------------------------------------------- */
NAREX.salesAndServiceSearchForm = function (formFields, $emptyResultBox, $manyResultsBox) {

  // fields
  this.$address = formFields.$address;
  this.$lat = formFields.$lat;
  this.$lng = formFields.$lng;
  this.$distanc = formFields.$distanc;
  this.$send = formFields.$send;

  // alert boxy
  this.$emptyResultBox = $emptyResultBox.removeClass('hidden');
  this.$manyResultsBox = $manyResultsBox.removeClass('hidden');

  // geocoder
  // geocoder
  this.geocoder = new google.maps.Geocoder();

  // schovani boxu
  this.hideMessageBoxes();

  // event pro hledani
  this.$send.click(jQuery.proxy(function (e) {
    e.preventDefault();
    if (this.$address.val() != '') {
      this.search(this.$address.val());
    }
  }, this));

};


  NAREX.salesAndServiceSearchForm.prototype.search = function (address) {
    this.geocoder.geocode({address: address}, jQuery.proxy(function (results, status) {

      // vysledky nalezeny - BEGIN
      if (status == google.maps.GeocoderStatus.OK) {

        // filtrovani vysledku
        results = NAREX.geocoderResultsFilters.excludeAddressTypes(results, ['route']);
        results = NAREX.geocoderResultsFilters.duplicateLatLng(results);
        //results = NAREX.geocoderResultsFilters.duplicateFormattedAddress(results);

        // zadny vysledek
        if (results.length == 0) {
          this.showEmptyResultBox();
        }

        // jeden vysledek
        else if (results.length == 1) {
          this.send(results[0]);
        }

        // vice vysledku
        else {
          this.showManyResultsBox(results)
        }

      }
      // vysledky nalezeny - END

      // vysledky nenalezenu - BEGIN
      else {
        this.showEmptyResultBox();
      }
      // vysledky nenalezenu - END

    }, this));
  };


  NAREX.salesAndServiceSearchForm.prototype.hideMessageBoxes = function () {
    jQuery('.content', this.$emptyResultBox).remove();
    jQuery('.content', this.$manyResultsBox).remove();
    this.$emptyResultBox.hide();
    this.$manyResultsBox.hide();
  };


  NAREX.salesAndServiceSearchForm.prototype.showEmptyResultBox = function () {
    this.hideMessageBoxes();
    this.$emptyResultBox.show();
  };


  NAREX.salesAndServiceSearchForm.prototype.showManyResultsBox = function (results) {

    this.hideMessageBoxes();

    var $ul = jQuery('<ul />').addClass('content');
    for (var i=0; i<results.length; i++) {

      var $a = jQuery('<a />')
        .attr('href', '#')
        .text(results[i].formatted_address)
        .data('result', results[i]);
      var $li = jQuery('<li />');
      $li.append($a);
      $ul.append($li);

      // event na linku - BEGIN
      $a.click(jQuery.proxy(function (e) {
        e.preventDefault();
        this.send(jQuery(e.target).data('result'));
      }, this));
      // event na linku - BEGIN

    }

    this.$manyResultsBox.append($ul);
    this.$manyResultsBox.show();

  };


  NAREX.salesAndServiceSearchForm.prototype.send = function (result) {
    this.$address.val(result.formatted_address);
    this.$lat.val(result.geometry.location.lat());
    this.$lng.val(result.geometry.location.lng());
    $form = this.$address.parents('form');
    //this.dumpResult2Console();
    $form.submit();
  };


  NAREX.salesAndServiceSearchForm.prototype.dumpResult2Console = function () {
    $form = this.$address.parents('form');
    jQuery.each(jQuery('input, select, textarea', $form), function (i, elm) {
      var $elm = jQuery(elm);
      console.log('id: ' + $elm.attr('id') + ' / name: ' + $elm.attr('id') + ' / val: ' + $elm.val());
    });
  };

/* -----------------------------------------------------------------------------
salesAndServiceSearchForm - END
============================================================================= */




/* =============================================================================
geocoderResultsFilters - BEGIN
- sada filtru pro vycisteni vysledku vracenych z geocoderu
----------------------------------------------------------------------------- */
NAREX.geocoderResultsFilters = {};


// excludeAddressTypes - BEGIN
// odstraneni nepouzivanych typu bodu
// https://developers.google.com/maps/documentation/geocoding/#Types
// zatim natvrdo odstranuje pouze typ 'route'
NAREX.geocoderResultsFilters.excludeAddressTypes = function (results, excludeTypes) {
  var filtered  = new Array;
  for (var i=0; i<results.length; i++) {
    if (jQuery.inArray(results[i].types.toString(), excludeTypes) == -1) {
      filtered.push(results[i]);
    }
  }
  return filtered;
};
// excludeAddressTypes - END


// duplicateLatLng - BEGIN
// duplicity podle GPS souradnic
// pouzije prvni lokaci z duplicit
NAREX.geocoderResultsFilters.duplicateLatLng = function (results) {
  var filtered = new Array;
  var latlngs = new Array;
  for (var i=0; i<results.length; i++) {
      var latlng = results[i].geometry.location.toString();
      if (jQuery.inArray(latlng, latlngs) == -1) {
          latlngs.push(latlng);
          filtered.push(results[i]);
      }
  }
  return filtered;
};
// duplicateLatLng - END


// duplicateFormattedAddress - BEGIN
// duplicity formatovane adresy
// pouzije prvni lokaci z duplicit
NAREX.geocoderResultsFilters.duplicateFormattedAddress = function (results) {
  var filtered = new Array;
  var addresses = new Array;
  for (var i=0; i<results.length; i++) {
      var address = results[i].formatted_address;
      if (jQuery.inArray(address, addresses) == -1) {
          addresses.push(address);
          filtered.push(results[i]);
      }
  }
  return filtered;
};
// duplicateFormattedAddress - END

/* -----------------------------------------------------------------------------
geocoderResultsFilters - END
============================================================================= */
