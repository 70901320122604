NAREX.SameHeightsElementGroup = function ($elements) {

    this.$elements = $elements;
    if (this.$elements.length < 2) {
        return;
    }

    window.setInterval(jQuery.proxy(function () {
        this.setSameHeights();
    }, this), 100);

};



    NAREX.SameHeightsElementGroup.prototype.setSameHeights = function () {

        // reset predchoziho css height
        this.$elements.css({height: 'auto'});

        // ziskani nejvetsi vysky
        var height = 0;
        for (var i=0; i<this.$elements.length; i++) {
            $item = jQuery(this.$elements[i]);
            if ($item.outerHeight() > height) {
                height = $item.outerHeight();
            }
        }

        // nastaveni vysky prvku
        this.$elements.css({height: height});

    };
