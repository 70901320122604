// namespace
var NAREX = NAREX || {};


// screen breakpoints
// dodjde-li k uprave ve __variables.less je potreba rucne upravit
NAREX.screenBreakpoints = {
    'xs': 480,
    'sm': 768,
    'md': 992,
    'lg': 1200
}


// defaultni delka textu pro zkracovani (truncate)
NAREX.truncateTextLengthDefault = 255;


/* MAIN - BEGIN */
jQuery(document).ready(function () {

    // setPlaceholder
    // - ASP.net neumi HTML5 atributy takze tady se to nastavuje natvrdo
    /* priklad definice v 'page specific script':
    NAREX.html5attributes = [
      {'selector': '#ctl00_search_phrase', 'attr': 'placeholder', 'val': 'Hledaný výraz...'},
      {'selector': '#email-input', 'attr': 'type', 'val': 'email'}
      ];
    */
    if (typeof NAREX.html5attributes != 'undefined') {
      NAREX.setHtml5attributes(NAREX.html5attributes);
    }

    // vytvori leve menu (ne pro homepage) "odtrzenim" casti hlavniho
    NAREX.createLeftMenu();

    // vytvori ze seznamu select s automatickym redirectem
    NAREX.selectRedirector();

    // replacable sticker
    NAREX.replaceableStickers();

    // truncate (zkraceni textu na pozadovanou delku)
    NAREX.truncateText(NAREX.truncateTextLengthDefault);

    // odesilaci formular
    NAREX.searchFormInit(NAREX.searchForm);

    // stejne vysky prvku
    var x = new Array;
    jQuery.each(jQuery('.pp-row'), function (i, elm) {
        xx = new NAREX.SameHeightsElementGroup(jQuery('.pp-item', jQuery(elm)));
        x.push(xx);
    });

});
/* MAIN - END */



/* vytvori leve menu z casti horniho - BEGIN */
NAREX.createLeftMenu = function () {

    var $origMenu = jQuery('#secondary-navbar');
    var $menu = $origMenu.clone(true)
        .removeClass('navbar-nav')
        .removeClass('navbar-right');

    // pripojeni do leveho sidebaru
    jQuery('#left-sidebar').prepend($menu);

    // pocatecni nastaveni viditelnosti - BEGIN
    if (BsTools.Screen.isXs() == true) {
        $menu.hide();
    }
    else {
        $origMenu.hide();
    }
    // pocatecni nastaveni viditelnosti - BEGIN


    // show/hide event - BEGIN
    jQuery(document).on('BsTools.Screen.switchDevice', function (e) {
        switch (e.device) {

            case 'xs':
                $menu.hide();
                $origMenu.show();
                break;

            default:
                $origMenu.hide();
                $menu.show();
                break;

        }
    });
    // show/hide event - END

}
/* vytvori leve menu z casti horniho - END */



/* vyrobi ze seznamu selectbox s automatickym redirektem - BEGIN */
NAREX.selectRedirector = function () {

    var $lists = jQuery('ul.select-redirector, ol.select-redirector');
    if ($lists.length < 1) {
        return;
    }

    // iterace nad listy - BEGIN
    for (var i=0; i<$lists.length; i++) {

        var $list = jQuery($lists[i]);
        var html = '<select class="select-redirector-select">';

        // iterace nad polozkami - BEGIN
        for (var ii=0; ii<$list.children('li').length; ii++) {
            var $item = jQuery($list.children('li')[ii]);
            html += '<option value="' + $item.children('a').attr('href') + '"';
            if ($item.hasClass('active')) {
                html += ' selected="selected"'
            }
            html += '>';
            html += $item.children('a').text();
            html += '</option>';
        }
        // iterace nad polozkami - END

        html += '</select>';
        $list.parent('li').html(html);

    }
    // iterace nad listy - END


    // eventy - BEGIN
    jQuery('.select-redirector-select').on('change', function (e) {
        e.preventDefault();
        var $select = jQuery(this);
        window.location = $select.val();
    });
    // eventy - END

}



/* nahrazuje element s class="replaceable-sticker" "stickerem" */
NAREX.replaceableStickers = function () {

    var $stickers = jQuery('.replaceable-sticker');
    if ($stickers.length == 0) {
        return;
    }

    for (var i=0; i<$stickers.length; i++) {

        var $textSticker = jQuery($stickers[i])
            .hide();

        var $sticker = jQuery('<img />')
            .attr('src', $textSticker.data('stickerImage'))
            .attr('alt', $textSticker.text())
            .css({
                position: 'absolute',
                right: 0,
                top: 0
            });

        var $target = $textSticker.parent($textSticker.data('stickerParentTarget'))
            .css({position: 'relative'})
            .append($sticker);

    }

}



/* zkracuje text s tridou 'truncate' (najde nejblizsi mezeru kratsi nez definovana delka) */
// pro prvky s class="truncate"
// vlastni delku lze nastavit pomoci data="truncate-length"
NAREX.truncateText = function (defaultLength) {

    var $truncates = jQuery('.truncate');
    if ($truncates.length == 0) {
        return;
    }


    for (var i=0; i<$truncates.length; i++) {

        var $truncate = jQuery($truncates[i]);

        if ($truncate.data('truncateLength')) {
            var truncateLength = $truncate.data('truncateLength');
        }
        else {
            var truncateLength = defaultLength;
        }

        $truncate.truncate({
            length: truncateLength
        }).html();

    }

}




// stejna vyska (nejvyssi z vybranych) prvku v selectoru
/*
NAREX.setSameHeights = function ($items) {

    var height = 0;
    var padding = {
        top: 0,
        bottom: 0
    };

    for (var i=0; i<$items.length; i++) {

        var $item = jQuery($items[i]);

        if ($item.outerHeight() > height) {
            height = $item.outerHeight();
        }

        if (parseInt($item.css('padding-top')) > padding.top) {
            padding.top = parseInt($item.css('padding-top'));
        }

        if (parseInt($item.css('padding-bottom')) > padding.bottom) {
            padding.bottom = parseInt($item.css('padding-bottom'));
        }

    }

        $items.css({
            height: height,
            paddingTop: padding.top,
            paddingBottom: padding.bottom
        });

}
*/

/*
// vysky prvky podle kontejneru
NAREX.setHeightToContainer = function (containerSelector, itemSelector) {

    var $containers = jQuery(containerSelector);

    for (var i=0; i<$containers.length; i++) {

        var $container = jQuery($containers[i]);
        var $items = jQuery(itemSelector, $container);
        var height = 0;

        // hidden objekty
        if (!$container.is(':visible')) {
            continue;
        }

        // ziskani vysky nejvyssiho prvku
        for (var ii=0; ii<$items.length; ii++) {
            $item = jQuery($items[ii]);
            if ($item.outerHeight() > height) {
                height = $item.outerHeight();
            }
        }

        // nastaveni vysky prvku
        $items.css({height: height});

    }
}
*/

// nahrada za nemoznost v APS.net primo definovat HTML5 attributy
NAREX.setHtml5attributes = function (definitions) {
  for (var i=0; i<definitions.length; i++) {
    var def = definitions[i];
    jQuery(def.selector).attr(def.attr, def.val);
  }
}



// inicializace vyhledavaciho formulare
NAREX.searchFormInit = function (options) {
  var input = jQuery('#' + options.searchPhraseId);
  var button = jQuery('#' + options.searchButtonId);
  button.click(function (e) {
    if (input.val() == '') {
      e.preventDefault();
    }
  });
}


/* DisplayToggler - BEGIN */
NAREX.DisplayToggler = function ($trigger) {

  var $trigger = $trigger;
  if ($trigger.length != 1) {
    return;
  }

  var $targets = jQuery($trigger.attr('data-displaytoggler-target'));
  $targets.removeClass('hide')
    .removeClass('hidden')
    .removeClass('sr-only')
    .hide();

  var hidetext = $trigger.attr('data-displaytoggler-hidetext');

  var showtext = '';
  if ($trigger.attr('data-displaytoggler-showtext')) {
    showtext = $trigger.attr('data-displaytoggler-showtext');
  }
  else {
    showtext = $trigger.html();
  }

  $trigger.on('click', function (e) {
    e.preventDefault();
    $targets.toggle();
    if ($trigger.html() == showtext) {
      $trigger.html(hidetext);
    }
    else {
      $trigger.html(showtext);
    }
  });

};

jQuery(document).ready(function () {
  var display_togglers = new Array;
  jQuery.each(jQuery("*[data-displaytoggler='trigger']"), function (i, elm) {
    var toggler = new NAREX.DisplayToggler(jQuery(elm));
    display_togglers.push(toggler);
  });
});
/* DisplayToogler - BEGIN */
