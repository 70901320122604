jQuery(document).ready(function() {
    (function($, undefined){
        events_minicalendar = new NAREX.EventsMinicalendar (NAREX, jQuery('#date-selector'));
    }).call(NAREX, jQuery);
});
/* APP - END ------------------------------------------------ */




/* =============================================================================
EventsMinicalendar - BEGIN
- z parametru predanych pres JS vytvori minikalendar eventu
----------------------------------------------------------------------------- */
NAREX.EventsMinicalendar = function (appObject, $appendPoint) {

    this.$appendPoint = false;
    this.displayMonth = false;
    this.displayYear = false;
    this.daysWithEvent = false;
    this.dayLinkTemplate = false;

    if (this.init(appObject, $appendPoint) == false) {
        return;
    }

    // language
    if (typeof appObject.languageCode == 'undefined') {
        this.languageCode = 'cs-CZ';
    }
    else {
        this.languageCode = appObject.languageCode;
    }

    // ziskani pole lokalizovanych nazvu dni
    this.localizedDayNames = this.getLocalizedDayNames(this.languageCode);

    // vytvoreni minikalendare
    this.create();

};


NAREX.EventsMinicalendar.prototype.init = function (appObject, $appendPoint) {

    if ($appendPoint.length != 1) {
        return false;
    }

    if (typeof appObject.events == 'undefined') {
        return false;
    }

    if (typeof appObject.events.displayMonth == 'undefined') {
        return false;
    }

    if (typeof appObject.events.displayYear == 'undefined') {
        return false;
    }

    if (jQuery.isArray(appObject.events.daysWithEvent) == false) {
        return false;
    }

    this.$appendPoint = $appendPoint;
    this.displayMonth = appObject.events.displayMonth;
    this.displayYear = appObject.events.displayYear;
    this.daysWithEvent = appObject.events.daysWithEvent;

    return true;

};




NAREX.EventsMinicalendar.prototype.create = function () {

    // pocet dnu aktualniho mesice
    var days_in_actual_month = 32 - new Date(this.displayYear, (this.displayMonth - 1), 32).getDate();

    // prvni den v tydnu aktualniho mesice
    var first_day_in_actual_month = new Date(this.displayYear, (this.displayMonth - 1)).getDay();

    // prevod javascriptovych dnu v tydnu na "normalni"
    // index je cesky, hodnota je JS
    var week_days = new Array(1, 2, 3, 4, 5, 6, 0);

    // vytvoreni kalendare - BEGIN

        // zacatek tabulky
        var html = '<table id="minicalendar">';
        html += '<thead><tr>';
        for (var i=0; i<week_days.length; i++) {
            html += '<th>' + this.localizedDayNames[week_days[i]] + '</th>';
        }
        html += '</tr></thead>';
        html += '<tbody>';

        // telo kalendare - BEGIN
        var day_counter = 1;
        var day_in_week = 1;


            // zacatek kalendare - BEGIN
            html += '<tr>';
            for (var x = 0; x < 7; x++) {
                if (week_days[x] != first_day_in_actual_month) {
                    html += '<td>&nbsp;</td>';
                    day_in_week++;
                }

                else {
                    break;
                }
            }

            for (var x = day_in_week; x <= 7; x++) {

                // tridy pro oznaceni dni
                var cls = new Array();

                // ma event
                if (jQuery.inArray(day_counter, this.daysWithEvent) != -1) {
                  cls.push('has-event');
                }

                // nema event
                else {
                  cls.push('day');
                }

                // je to 'dnesek'
                if (this.isToday(this.displayYear, this.displayMonth, day_counter) == true) {
                    cls.push('today');
                }

                // sestaveni td dne
                html += '<td class="' + cls.join(' ') + '">' + day_counter + '</td>';

                day_in_week++;
                day_counter++;
            }

            html += '</tr>';
            day_in_week = 1;
            // zacatek kalendare - BEGIN


            // stred tabulky - BEGIN
            html += '<tr>';
            for (var x = day_counter; x <= days_in_actual_month; x++) {

              // tridy pro oznaceni dni
              var cls = new Array();

              // ma event
              if (jQuery.inArray(day_counter, this.daysWithEvent) != -1) {
                cls.push('has-event');
              }

              // nema event
              else {
                cls.push('day');
              }

              // je to 'dnesek'
              if (this.isToday(this.displayYear, this.displayMonth, day_counter) == true) {
                  cls.push('today');
              }

              // sestaveni td dne
              html += '<td class="' + cls.join(' ') + '">' + day_counter + '</td>';

              if (day_in_week == 7) {
                  html += '</tr><tr>';
                  day_in_week = 1;
              }

              else {
                  day_in_week++;
              }

              day_counter++;

            }
            // stred tabulky - END

            // dokonceni tela - BEGIN
            if (day_in_week != 7) {
                for (var x = day_in_week; x <= 7; x++) {
                    html += '<td>&nbsp;</td>';
                    if (x == 7) {
                        html += '</tr>';
                    }
                }
            }
            // dokonceni tela - END

        // telo kalendare - END

        // konec tabulky
        html += '</tbody>';
        html += '</table>';

    // vytvoreni kalendare - END

    // vlozeni kalendare do stranky
    this.$appendPoint.append(html);

};


NAREX.EventsMinicalendar.prototype.getLocalizedDayNames = function (languageCode) {
    // dny jsou indexovany podle konvence Date() tzn. nedele = 0, pondeli = 1, ...

    var localizedDayNames = [];
    var d = new Date();
    var todayIndex = d.getDay();
    var sunday = new Date();
    sunday.setDate(d.getDate() - todayIndex);

    for (var i=0; i<7; i++) {
        var day = new Date();
        day.setDate(sunday.getDate() + i);
        localizedDayNames.push(day.toLocaleString(languageCode, {weekday: 'short'}));
    }

    return localizedDayNames;

}


NAREX.EventsMinicalendar.prototype.isToday = function (year, month, day_counter) {

    var today = new Date();
    today_year = today.getFullYear();
    today_month = today.getMonth() + 1;
    today_day = today.getDate();

    if (year == today_year
        && month == today_month
        && today_day == day_counter) {
        return true;
    }

    else {
        return false;
    }

}
