// http://www.zdrojak.cz/clanky/tridy-dedicnost-a-oop-v-javascriptu-ii/
if (typeof extendClass == 'undefined') {
    var extendClass = function (child, parent) {
        var F = function () {};
        F.prototype = parent.prototype;
        child.prototype = new F();
        child._superClass = parent.prototype;
        child.prototype.constructor = child;
    };
}
/* vytvari zdedenou tridu - END */


/* APP - BEGIN ---------------------------------------------- */
jQuery(document).ready(function() {
    (function($, undefined){

        gallerist = new Gallerist(jQuery('.gallery-thumbnails a'));
        jQuery('.product-image a').on('click', function (e) {
            e.preventDefault();
            gallerist.show(0);
        });

    }).call(Gallerist, jQuery);
});
/* APP - END ------------------------------------------------ */




/* GALLERIST - BEGIN ---------------------------------------- */
Gallerist = function ($thumbnails) {

    this.zoomStep = 20;

    this.$elm = jQuery('#gallerist-modal');
    this.items = [];
    this.actualIndex = 0;
    this.actualItem = false;

    for (var i=0; i<$thumbnails.length; i++) {
        var $thumbnail = jQuery($thumbnails[i]);
        this.addItem($thumbnail);
        $thumbnail.on('click', jQuery.proxy(function (e) {
            e.preventDefault();
            var carouselIndex = jQuery(e.currentTarget).data('carouselIndex');
            this.show(carouselIndex);
        }, this));
    }

    this.$carousel = this.initCarousel();

    // buttons - BEGIN
    this.buttons = {
        close: new Gallerist.Buttons.Close(jQuery('.carousel-pager-close'), this),
        next: new Gallerist.Buttons.Next(jQuery('.carousel-pager-next'), this),
        prev: new Gallerist.Buttons.Prev(jQuery('.carousel-pager-prev'), this),
        zoomIn: new Gallerist.Buttons.ZoomIn(jQuery('.carousel-pager-zoom-in'), this),
        zoomOut: new Gallerist.Buttons.ZoomOut(jQuery('.carousel-pager-zoom-out'), this),
        zoom: new Gallerist.Buttons.Zoom(jQuery('.carousel-pager-zoom'), this),
        pan: new Gallerist.Buttons.Pan(jQuery('.carousel-pager-pan'), this),
    }
    // buttons - END

};


    Gallerist.prototype.show = function (index) {

        if (typeof this.items[index] == 'undefined') {
            return;
        }

        if (typeof this.items[this.actualIndex] != 'undefined') {
            this.items[this.actualIndex].hide();
        }

        this.actualIndex = index;
        this.actualItem = this.items[this.actualIndex];
        this.actualItem.show();

        this.$carousel.carousel(index);
        this.$elm.modal('show');

        // pocatecni stav tlacitek 'next' a 'prev'
        this.buttons.next.enable();
        this.buttons.prev.enable();

        // neni zadny predchozi item
        if (this.actualIndex == 0) {
            this.buttons.prev.disable();
        }

        // neni zadny nasledujici prvek
        if (this.actualIndex == (this.items.length - 1)) {
            this.buttons.next.disable();
        }

    };


    Gallerist.prototype.hide = function () {
        this.$elm.modal('hide');
        jQuery.map(this.items, function (item) {
            item.isHide();
        });
    };


    Gallerist.prototype.next = function () {
        if (typeof this.items[this.actualIndex + 1] != 'undefined') {
            this.show(this.actualIndex + 1);
        }
    };


    Gallerist.prototype.prev = function () {
        if (typeof this.items[this.actualIndex - 1] != 'undefined') {
            this.show(this.actualIndex - 1);
        }
    };


    Gallerist.prototype.addItem = function ($thumbnail) {

        // item
        var index = this.items.length;
        var item = Gallerist.Item.factory($thumbnail, index, this);
        this.items.push(item);

        // carousel item - BEGIN
        var $item = jQuery('<div />')
            .addClass('item')
            .data('carouselIndex', index)
            .append(item.getContent());
        if (index == 0) {
            $item.addClass('active');
        }

        jQuery('.carousel-inner', this.$item).append($item);
        // carousel item - END

        // pager - BEGIN
        var $pagerItem = jQuery('<li />')
            .attr('data-target', '#gallerist-carousel')
            .attr('data-slide-to', index);
        if (index == 0) {
            $pagerItem.addClass('active');
        }
        jQuery('.carousel-indicators', this.$elm).append($pagerItem);
        // pager - END

    };


    Gallerist.prototype.initCarousel = function () {

        var $carousel = jQuery('#gallerist-carousel');

        $carousel.carousel({
            interval: false
        });

        $carousel.on('slid.bs.carousel', jQuery.proxy(function (e) {
            var currentItemIndex = jQuery(e.relatedTarget).data('carouselIndex');
            jQuery.map(this.items, function (item) {
                item.isHide();
            });
            this.items[currentItemIndex].isVisible();
        }, this));

        return $carousel;
    };


    Gallerist.prototype.getCanvasSize = function () {
        var $canvas = jQuery('.item', this.$carousel);
        return {
            width: $canvas.innerWidth(),
            height: $canvas.innerHeight()
        };
    };

/* GALLERIST - END ------------------------------------------ */




    /* GALLERIST.ITEM - BEGIN ---------------------------------- */
    Gallerist.Item = {};
    /* GALLERIST.ITEM - END ------------------------------------- */



    /* GALLERIST.ITEMFACTORY - BEGIN ---------------------------- */
    Gallerist.Item.factory = function ($thumbnail, index, gallerist) {

        if (typeof $thumbnail.data('panoramaImages') != 'undefined') {
            var i = new Gallerist.Item.Panorama($thumbnail, index, gallerist);
        }

        else {
            var i = new Gallerist.Item.Image($thumbnail, index, gallerist);
        }

        return i;
    }
    /* GALLERIST.ITEMFACTORY - BEGIN ---------------------------- */



    /* GALLERIST.ITEM.ITEM - BEGIN ------------------------------ */
    Gallerist.Item.Item = function ($thumbnail, index, gallerist) {
        this.gallerist = gallerist;
        this.$thumbnail = $thumbnail;
        this.index = index;
        this.imgSrc = $thumbnail.attr('href');
        $thumbnail.data('carouselIndex', index);
        this.$content = this.getContent();
        this.maxSize = this.getMaxSize();
    };


        Gallerist.Item.Item.prototype.getContent = function () {

            if (typeof this.$content == 'undefined') {
                this.$content = jQuery('<img />').attr('src', this.imgSrc);
            }

            return this.$content;

        };


        Gallerist.Item.Item.prototype.getCanvas = function () {
            return this.$content.parent('div.item');
        };


        Gallerist.Item.Item.prototype.getMaxSize = function () {

            if (typeof this.maxSize != 'undefined') {
                return this.maxSize;
            }

            else {
                var img = new Image();
                img.src = this.imgSrc;
                var self = this;
                img.onload = function () {
                    self.maxSize = {
                        width: this.width,
                        height: this.height
                    };
                }
            }

        };


        Gallerist.Item.Item.prototype.isVisible = function () {
            this.$thumbnail.addClass('active');
        };


        Gallerist.Item.Item.prototype.isHide = function () {
            this.$thumbnail.removeClass('active');
        };


        Gallerist.Item.Item.prototype.show = function () {

            this.setZoomButtons();

            // trackovani v Google Analytics - BEGIN
            if (typeof window._gaq != 'undefined') {
                var category = 'Lightbox.' + this.itemType;
                var action = 'Display';
                _gaq.push(['_trackEvent', category, action, this.imgSrc]);
            }
            // trackovani v Google Analytics - END

        };


        Gallerist.Item.Item.prototype.hide = function () {
        };


        Gallerist.Item.Item.prototype.zoomIn = function () {

            // zmena velikosti
            var newSize = this.calculateZoom(this.gallerist.zoomStep);
            this.resize(newSize);
            this.setPosition(newSize);
            this.setZoomButtons();

            // nastaveni moznosti posouvat
            if (newSize.width > this.getMinSize().width || newSize.height > this.getMinSize().height) {
                this.setDraggable();
            }
            else {
                this.unsetDraggable();
            }

        };


        Gallerist.Item.Item.prototype.zoomOut = function () {

            // zmena velikosti
            var newSize = this.calculateZoom(-this.gallerist.zoomStep);
            this.resize(newSize);
            this.setPosition(newSize);
            this.setZoomButtons();

            // nastaveni moznosti posouvat
            if (newSize.width > this.getMinSize().width || newSize.height > this.getMinSize().height) {
                this.setDraggable();
            }
            else {
                this.unsetDraggable();
            }

        };


        Gallerist.Item.Item.prototype.zoom = function () {
        };


        Gallerist.Item.Item.prototype.pan = function () {
        };


        Gallerist.Item.Item.prototype.resize = function (newSize) {

            var $canvas = this.getCanvas();

            $canvas.css({
                width: $canvas.width(),
                height: $canvas.height(),
                overflow: 'hidden',
                position: 'relative'
            });

            this.$content.css({
                width: newSize.width,
                height: newSize.height,
                'max-width': newSize.width,
                position: 'absolute'
            });

        };


        Gallerist.Item.Item.prototype.getItemSize = function () {
            return itemSize = {
                width: this.$content.width(),
                height: this.$content.height()
            };
        };


        Gallerist.Item.Item.prototype.getMinSize = function () {
            return this.gallerist.getCanvasSize();
        };


        Gallerist.Item.Item.prototype.calculateZoom = function (diff) {

            var maxSize = this.getMaxSize();
            var minSize = this.getMinSize();
            var itemSize = this.getItemSize();

            var newSize = {
                width: parseInt(itemSize.width * ((100 + diff) / 100)),
                height: parseInt(itemSize.height * ((100 + diff) / 100)),
            };

            // vetsi nez povolena velikost (max velikost obrazku)
            if (newSize.width > maxSize.width || newSize.height > maxSize.height) {
                return maxSize;
            }

            // mensi nez povolena velikost (nez velikost canvasu)
            if (newSize.width < minSize.width || newSize.height < minSize.height) {
                return minSize;
            }

            return newSize;

        };


        Gallerist.Item.Item.prototype.setPosition = function (newSize) {

            var $canvas = this.getCanvas();
            var newPosition = {
                left: parseInt(($canvas.innerWidth() - newSize.width) / 2),
                top: parseInt(($canvas.innerHeight() - newSize.height) / 2)
            };

            this.$content.css({
                left: newPosition.left,
                top: newPosition.top
            });

        };


        Gallerist.Item.Item.prototype.setDraggable = function () {

            // nastaveni kurzoru
            this.$content.css('cursor', 'move');

            // containment - BEGIN
            $canvas = this.getCanvas();
            var offset = $canvas.offset();
            var cont = [
                offset.left + ($canvas.width() - this.$content.width()),
                offset.top + ($canvas.height() - this.$content.height()),
                offset.left,
                offset.top
            ];
            // containment - END

            this.$content.draggable({containment: cont});
        };


        Gallerist.Item.Item.prototype.unsetDraggable = function () {

            // nastaveni kurzoru
            this.$content.css('cursor', 'auto');

            // nastaveni jQuery draggable
            this.$content.draggable();
            this.$content.draggable('disable');
            this.$content.draggable('destroy');
            this.$content.css({left : 0, top : 0});

        };


        Gallerist.Item.Item.prototype.setZoomButtons = function () {

            // zoomIn
            if (this.getItemSize().width < this.getMaxSize().width && this.getItemSize().height < this.getMaxSize().height) {
                this.gallerist.buttons.zoomIn.enable();
            }
            else {
                this.gallerist.buttons.zoomIn.disable();
            }

            // zoomOut
            if (this.getItemSize().width == this.getMinSize().width && this.getItemSize().height == this.getMinSize().height) {
                this.gallerist.buttons.zoomOut.disable();
            }
            else {
                this.gallerist.buttons.zoomOut.enable();
            }

        };

    /* GALLERIST.ITEM.ITEM - END -------------------------------- */




    /* GALLERIST.ITEM.IMAGE - BEGIN ----------------------------- */
    Gallerist.Item.Image = function ($thumbnail, index, gallerist) {

        // bazovy konstruktor
        Gallerist.Item.Item.call(this, $thumbnail, index, gallerist);

        this.itemType = 'image';

    };

    extendClass(Gallerist.Item.Image, Gallerist.Item.Item);


        Gallerist.Item.Image.prototype.show = function () {

            // zobrazeni tlacitek pro zoomovani
            this.gallerist.buttons.zoomIn.show();
            this.gallerist.buttons.zoomOut.show();

            // aktivace stavu zoomovani
            this.setZoomButtons();

        };

    /* GALLERIST.ITEM.IMAGE - END ------------------------------- */



    /* GALLERIST.ITEM.PANORAMA - BEGIN -------------------------- */
    Gallerist.Item.Panorama = function ($thumbnail, index, gallerist) {

        // bazovy konstruktor
        Gallerist.Item.Item.call(this, $thumbnail, index, gallerist);

        this.itemType = 'panorama';
        this.panoramaInitialised = false;
        this.state = false;

    };

    extendClass(Gallerist.Item.Panorama, Gallerist.Item.Item);


        Gallerist.Item.Panorama.prototype.isVisible = function () {
            Gallerist.Item.Panorama._superClass.isVisible.call(this);
            if (!this.panoramaInitialised) {
                window.setTimeout(jQuery.proxy(this.initPanorama, this), 1000);
            }
        };


        Gallerist.Item.Panorama.prototype.show = function () {
            Gallerist.Item.Panorama._superClass.show.call(this);

            // rozsviceni tlacitek specifickych pro panorama
            this.gallerist.buttons.zoom.show();
            this.gallerist.buttons.pan.show();

            // prvotni nastaveni tlacitek
            if (this.state == false) {
                this.pan();
            }

        };


        Gallerist.Item.Panorama.prototype.hide = function () {
            Gallerist.Item.Panorama._superClass.hide.call(this);

            // zhasnuti tlacitek specifickych pro panorama
            this.gallerist.buttons.zoom.hide();
            this.gallerist.buttons.pan.hide();

            // aktivace zoomovani
            this.setZoomButtons();

        };


        Gallerist.Item.Panorama.prototype.zoom = function () {
            Gallerist.Item.Panorama._superClass.zoom.call(this);

            // state
            this.state = 'zoom';

            // skryti panoramatu
            this.disablePanorama();

            // nastaveni tlacitek specifickych pro panorama
            this.gallerist.buttons.zoom.disable();
            this.gallerist.buttons.pan.enable();

            // zobrazeni tlacitek pro zoomovani
            this.gallerist.buttons.zoomIn.show();
            this.gallerist.buttons.zoomOut.show();

            // aktivace stavu zoomovani
            this.setZoomButtons();

        };


        Gallerist.Item.Panorama.prototype.pan = function () {
            Gallerist.Item.Panorama._superClass.pan.call(this);

            // zobrazeni panoramatu
            if (this.state != false) {
                this.initPanorama();
            }

            // state
            this.state = 'pan';

            // nastaveni tlacitek specifickych pro panorama
            this.gallerist.buttons.pan.disable();
            this.gallerist.buttons.zoom.enable();

            // skryti tlacitek pro zoomovani
            this.gallerist.buttons.zoomIn.hide();
            this.gallerist.buttons.zoomOut.hide();

        };


        Gallerist.Item.Panorama.prototype.getActualPanoramaIndex = function () {
            var $overlay = this.$content.parent('.reel-overlay');
            var classes = $overlay.attr('class').split(' ');
            for (var i=0; i<classes.length; i++) {
                var c = jQuery.trim(classes[i]);
                if (c.substr(0, 6) == 'frame-') {
                    return parseInt(c.substr(6));
                }
            }
        };


        Gallerist.Item.Panorama.prototype.getActualPanoramaImage = function () {
            var img = new Image();
            img.src = this.$content.attr('src');
            return img;
        };


        Gallerist.Item.Panorama.prototype.initPanorama = function () {

            // pocatecni nastaveni startovniho frame
            if (typeof this.$thumbnail.data('panoramaStartIndex') == 'undefined') {
                this.$thumbnail.data('panoramaStartIndex', 1);
            }

            // spusteni reel
            this.$content
                .attr('width', this.$content.width())
                .attr('height', this.$content.height())
                .reel({
                    images: this.$thumbnail.data('panoramaImages'),
                    frame: this.$thumbnail.data('panoramaStartIndex')
                });

            // nastaveni kurzoru
            this.$content.css({
                cursor: 'url("http://code.vostrel.net/jquery.reel.cur"), move'
            });

            // flag inicializace
            this.panoramaInitialised = true;

        };


        Gallerist.Item.Panorama.prototype.disablePanorama = function () {

            // ziskani indexu aktualne zobrazovaneho frame
            this.$thumbnail.data('panoramaStartIndex', this.getActualPanoramaIndex());

            // ziskani src aktualne zobrazovaneho frame
            var newImg = this.getActualPanoramaImage();

            // vypnuti panoramatu
            var $overlay = this.$content.parent('.reel-overlay');
            $overlay.unreel();

            // nastaveni contentu na posledni zobrazovany image
            this.$content.attr('src', newImg.src);

            // flag inicializace
            this.panoramaInitialised = false;

        };

    /* GALLERIST.ITEM.PANORAMA - END ---------------------------- */




    /* GALLERIST.BUTTONS - BEGIN ----------------------------------- */
    Gallerist.Buttons = {};
    /* GALLERIST.BUTTONS - END ------------------------------------- */


    /* GALLERIST.BUTTONS.BUTTON - BEGIN ---------------------------- */
    Gallerist.Buttons.Button = function ($button, gallerist) {

        this.$button = $button;
        this.gallerist = gallerist;

    };

        Gallerist.Buttons.Button.prototype.click = function () {

        };

        Gallerist.Buttons.Button.prototype.disable = function () {
            this.$button.addClass('disabled');
        };

        Gallerist.Buttons.Button.prototype.enable = function () {
            this.$button.removeClass('disabled');
        };

        Gallerist.Buttons.Button.prototype.isEnable = function () {
            return ! this.$button.hasClass('disabled');
        };

        Gallerist.Buttons.Button.prototype.show = function () {
            this.$button.removeClass('hide');
            this.$button.show();
        };

        Gallerist.Buttons.Button.prototype.hide = function () {
            this.$button.hide();
        };

    /* GALLERIST.BUTTONS.BUTTON - END ---------------------------- */



    /* GALLERIST.BUTTONS.CLOSE - BEGIN --------------------------- */
    Gallerist.Buttons.Close = function ($button, gallerist) {

        // bazovy konstruktor
        Gallerist.Buttons.Button.call(this, $button, gallerist);

        // click event
        this.$button.on('click', jQuery.proxy(function (e) {
            e.preventDefault();
            if (this.isEnable()) {
                this.gallerist.actualItem.hide();
                this.gallerist.hide();
            }
        }, this));

    };

    extendClass(Gallerist.Buttons.Close, Gallerist.Buttons.Button);
    /* GALLERIST.BUTTONS.CLOSE - END ----------------------------- */



    /* GALLERIST.BUTTONS.NEXT - BEGIN ---------------------------- */
    Gallerist.Buttons.Next = function ($button, gallerist) {

        // bazovy konstruktor
        Gallerist.Buttons.Button.call(this, $button, gallerist);

        // click event
        this.$button.on('click', jQuery.proxy(function (e) {
            e.preventDefault();
            if (this.isEnable()) {
                this.gallerist.next();
            }
        }, this));

    };

    extendClass(Gallerist.Buttons.Next, Gallerist.Buttons.Button);
    /* GALLERIST.BUTTONS.NEXT - END ------------------------------ */



    /* GALLERIST.BUTTONS.PREV - BEGIN ---------------------------- */
    Gallerist.Buttons.Prev = function ($button, gallerist) {

        // bazovy konstruktor
        Gallerist.Buttons.Button.call(this, $button, gallerist);

        // click event
        this.$button.on('click', jQuery.proxy(function (e) {
            e.preventDefault();
            if (this.isEnable()) {
                this.gallerist.prev();
            }
        }, this));

    };

    extendClass(Gallerist.Buttons.Prev, Gallerist.Buttons.Button);
    /* GALLERIST.BUTTONS.PREV - END ------------------------------ */



    /* GALLERIST.BUTTONS.ZOOMIN - BEGIN -------------------------- */
    Gallerist.Buttons.ZoomIn = function ($button, gallerist) {

        // bazovy konstruktor
        Gallerist.Buttons.Button.call(this, $button, gallerist);

        // click event
        this.$button.on('click', jQuery.proxy(function (e) {
            e.preventDefault();
            if (this.isEnable()) {
                this.gallerist.actualItem.zoomIn();
            }
        }, this));

    };

    extendClass(Gallerist.Buttons.ZoomIn, Gallerist.Buttons.Button);
    /* GALLERIST.BUTTONS.ZOOMIN - END ---------------------------- */



    /* GALLERIST.BUTTONS.ZOOMOUT - BEGIN ------------------------- */
    Gallerist.Buttons.ZoomOut = function ($button, gallerist) {

        // bazovy konstruktor
        Gallerist.Buttons.Button.call(this, $button, gallerist);

        // click event
        this.$button.on('click', jQuery.proxy(function (e) {
            e.preventDefault();
            if (this.isEnable()) {
                this.gallerist.actualItem.zoomOut();
            }
        }, this));

    };

    extendClass(Gallerist.Buttons.ZoomOut, Gallerist.Buttons.Button);
    /* GALLERIST.BUTTONS.ZOOMOUT - END --------------------------- */



    /* GALLERIST.BUTTONS.ZOOM - BEGIN ---------------------------- */
    Gallerist.Buttons.Zoom = function ($button, gallerist) {

        // bazovy konstruktor
        Gallerist.Buttons.Button.call(this, $button, gallerist);

        // click event
        this.$button.on('click', jQuery.proxy(function (e) {
            e.preventDefault();
            if (this.isEnable()) {
                this.gallerist.actualItem.zoom();
            }
        }, this));

    };

    extendClass(Gallerist.Buttons.Zoom, Gallerist.Buttons.Button);
    /* GALLERIST.BUTTONS.ZOOM - END ------------------------------ */



    /* GALLERIST.BUTTONS.PAN - BEGIN ----------------------------- */
    Gallerist.Buttons.Pan = function ($button, gallerist) {

        // bazovy konstruktor
        Gallerist.Buttons.Button.call(this, $button, gallerist);

        // click event
        this.$button.on('click', jQuery.proxy(function (e) {
            e.preventDefault();
            if (this.isEnable()) {
                this.gallerist.actualItem.pan();
            }
        }, this));

    };

    extendClass(Gallerist.Buttons.Pan, Gallerist.Buttons.Button);
    /* GALLERIST.BUTTONS.PAN - END ------------------------------- */
