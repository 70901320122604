var StickersNavigation = function ($menu) {

    this.$origMenu = $menu;
    this.$menu = $menu.clone(true)
        .attr('id', 'stickers-navbar-stickered-stuck')
        .appendTo(jQuery('body'));

    this.$contentContainer = jQuery('#content-container');

    // show/hide event - BEGIN
    jQuery(document).on('BsTools.Screen.switchDevice', jQuery.proxy(function (e) {
        switch (e.device) {

            case 'xs':
                this.hide();
                break;

            default:
                this.show();
                break;

        }
    }, this));
    // show/hide event - END

    // set position event - BEGIN
    jQuery(window).on('resize', jQuery.proxy(function (e) {
        this.setPosition();
    }, this));
    // set position event - END

};


StickersNavigation.prototype.setPosition = function () {

    // nastaveni na '0' po rotaci -90deg
    var left = -(this.$menu.outerWidth() / 2) + (this.$menu.outerHeight() / 2);
    var top =  -(this.$menu.outerHeight() / 2) + (this.$menu.outerWidth() / 2);

    // posun na "0" container
    left += this.$contentContainer.offset().left;
    top += this.$contentContainer.offset().top;

    // zarovnani na "right" containeru
    left += this.$contentContainer.outerWidth();

    // posun y
    top += 60;

    this.$menu.css({
        left: left,
        top: top
    });
};


StickersNavigation.prototype.show = function () {
    this.$origMenu.hide();
    this.$menu.show();
    this.setPosition();
};


StickersNavigation.prototype.hide = function () {
    this.$menu.hide();
    this.$origMenu.show();
};

/* MAIN - BEGIN */
jQuery(document).ready(function () {
    var stickersNavigation = new StickersNavigation(jQuery('#stickers-navbar'));
});
/* MAIN - END */
